import React, { useState, useEffect, useRef } from "react";
import axios from "../api/axiosConfig";
import Swal from "sweetalert2";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import Loader from "./loader";
import "../index.css";
import { io } from "socket.io-client";

const Clientes = () => {
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [edad, setEdad] = useState("");
  const [sexo, setSexo] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [telefono, setTelefono] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [email, setEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [clientesList, setClientesList] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sucursalesList, setSucursalesList] = useState([]);
  const userType = localStorage.getItem("userType");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalClientes, setTotalClientes] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentClientes, setCurrentClientes] = useState(0);

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const fetchData = async () => {
    try {
      let fetchedClientes = [];

      if (userType === "0") {
        const response = await axios.get("/clientes");
        fetchedClientes = response.data.data;
      } else {
        const response = await axios.get(
          `/clientesPorSuc/${localStorage.getItem("branchId")}`
        );
        fetchedClientes = response.data.data;
      }

      setClientesList(fetchedClientes);

      // Calcula las páginas totales basadas en los clientes cargados
      const totalPages = Math.ceil(fetchedClientes.length / clientesPerPage);
      setTotalClientes(totalPages);

      // Verifica si hay un término de búsqueda en localStorage
      const searchPaciente = localStorage.getItem("PacienteSearch") || "";

      if (searchPaciente.trim() !== "") {
        setSearchTerm(searchPaciente);
        // Filtra los clientes basados en el término de búsqueda, asegurándose de que el nombre no sea null o vacío
        const filteredClientes = fetchedClientes.filter((cliente) => {
          const nombreCompleto = cliente.nombreCompleto || ""; // Si nombreCompleto es null o undefined, lo convierte en cadena vacía
          return nombreCompleto
            .toLowerCase()
            .includes(searchPaciente.toLowerCase());
        });
        setCurrentClientes(filteredClientes.slice(0, clientesPerPage));
        setCurrentPage(1); // Reinicia la paginación
      } else {
        // Si no hay búsqueda, muestra la primera página de clientes
        setCurrentClientes(fetchedClientes.slice(0, clientesPerPage));
      }
    } catch (error) {
      console.error("Error fetching clients", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSucursales();
  }, [userType]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifica si el clic ocurrió fuera del contenedor del dropdown
      if (event.target.closest(".dropdown") === null) {
        setOpenDropdown(null); // Cerrar dropdown
      }
    };

    // Agregar el evento 'mousedown' al documento
    document.addEventListener("mousedown", handleClickOutside);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //Socket that detects new users created
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_BASE_URL,{
      extraHeaders: {
        'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
      },
      transports: ["websocket", "polling"]
    });

    // Escuchar eventos de nuevos clientes
    socket.on("NewClientAdded", () => {
      fetchData();
    });

    return () => socket.disconnect(); // Limpiar al desmontar
  }, []);

 //Socket that detects users updated
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_BASE_URL,{
      extraHeaders: {
        'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
      },
      transports: ["websocket", "polling"]
    });

    // Escuchar eventos de nuevos clientes
    socket.on("UpdatedClient", () => {
      fetchData();
    });

    return () => socket.disconnect(); // Limpiar al desmontar
  }, []);


 //Socket that detects users Deleted
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_BASE_URL,{
      extraHeaders: {
        'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
      },
      transports: ["websocket", "polling"]
    });

    // Escuchar eventos de nuevos clientes
    socket.on("DeletedClient", () => {
      fetchData();
    });

    return () => socket.disconnect(); // Limpiar al desmontar
  }, []);

  const fetchSucursales = () => {
    axios
      .get("/sucursales/active")
      .then((response) => {
        setSucursalesList(response.data.data);
      })
      .catch((error) => {
        console.error("Error al obtener sucursales:", error);
      });
  };

  const handleCreateOrUpdate = async (event) => {
    event.preventDefault();
    let sucursalId = localStorage.getItem("branchId");
    const cliente = {
      id: editMode,
      nombreCompleto,
      fechaNacimiento: fechaNacimiento || null,
      edad: edad || null,
      sexo: sexo || null,
      domicilio: domicilio || null,
      telefono: telefono || null,
      telefono2: telefono2 || null,
      email: email || null,
      rfc: rfc || null,
      codigoPostal: codigoPostal || null,
      sucursal: sucursalId || null,
    };

    try {
      if (editMode !== null) {
        await axios.put(`/clientes/${editMode}`, cliente);
        const updatedClientesList = clientesList.map((c) =>
          c.id === editMode ? cliente : c
        );
        setClientesList(updatedClientesList);
        setCurrentClientes(updatedClientesList);
        Swal.fire(
          "¡Actualizado con éxito!",
          "El paciente ha sido actualizado.",
          "success"
        );
        
        resetForm();
      } else {
        const usuarioExist = currentClientes.find((cli) => {
          return (
            cli.nombreCompleto == cliente.nombreCompleto &&
            cli.telefono == cliente.telefono
          );
        });
        if (!usuarioExist) {
          const response = await axios.post("/clientes/crearCliente", cliente); // Cliente recién creado
          setClientesList([...clientesList, cliente]); // Agregar el nuevo cliente a la lista existente
          setCurrentClientes([...currentClientes, cliente]); // Actualizar la lista de clientes actual
          Swal.fire(
            "¡Creado con éxito!",
            "El paciente ha sido creado.",
            "success"
          ).then(() => {
            window.location.reload();
          });
          
          resetForm();
        }else{
          Swal.fire(
            "¡El paciente ya existe!",
            "El paciente ya está registrado en la base de datos, favor de buscarlo.",
            "info"
          );
        }
      }
    } catch (error) {
      console.error("Error saving paciente", error);
    }
  };

  const handleEdit = (cliente) => {
    const fechaNacimientoOnlyDate =
      cliente.fechaNacimiento !== null
        ? cliente.fechaNacimiento.split("T")[0]
        : "";
    setNombreCompleto(cliente.nombreCompleto || "");
    setFechaNacimiento(fechaNacimientoOnlyDate || "");
    setEdad(cliente.edad || "");
    setSexo(cliente.sexo || "");
    setDomicilio(cliente.domicilio || "");
    setTelefono(cliente.telefono || "");
    setTelefono2(cliente.telefono2 || "");
    setEmail(cliente.email || "");
    setRfc(cliente.rfc || "");
    setCodigoPostal(cliente.codigoPostal || "");
    setEditMode(cliente.id);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Eliminar paciente",
      text: "¿Estás seguro de que deseas eliminar al paciente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`/clientes/${id}`);
          if (response.data.success) {
            // Si la solicitud fue exitosa, actualiza la lista de clientes
            const updatedClientesList = clientesList.filter(
              (cliente) => cliente.id !== id
            );
            setClientesList(updatedClientesList);
            setCurrentClientes(updatedClientesList); // Actualizar la lista de clientes actual
            // Muestra un mensaje de éxito al usuario
            Swal.fire({
              title: "Paciente eliminado!",
              text: "El paciente ha sido eliminado exitosamente.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            // Si la solicitud no fue exitosa, muestra un mensaje de error al usuario
            Swal.fire({
              title: "¡Error!",
              text: "No se pudo eliminar el paciente.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          // Si hubo un error en la solicitud, muestra un mensaje de error al usuario
          console.error("Error al eliminar el usuario:", error);
          Swal.fire({
            title: "¡Error!",
            text: "Ocurrió un error al intentar eliminar el paciente.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        Swal.close();
      }
    });
  };

  const resetForm = () => {
    setNombreCompleto("");
    setFechaNacimiento("");
    setEdad("");
    setSexo("");
    setDomicilio("");
    setTelefono("");
    setTelefono2("");
    setEmail("");
    setRfc("");
    setCodigoPostal("");
    setEditMode(null);
  };

  // Dentro de la función handleSearch
  const handleSearch = (searchValue) => {
    const searchTerm = searchValue.toLowerCase();
    setSearchTerm(searchTerm);
    localStorage.setItem("PacienteSearch", searchTerm);
    setCurrentPage(1); // Siempre comienza desde la primera página
    setCurrentClientes(filterClientes(1, searchTerm));
  };

  const handleSearchSaved = (value) => {
    const searchTerm = value.toLowerCase();
    setSearchTerm(searchTerm);
    localStorage.setItem("PacienteSearch", searchTerm);
    // Siempre comienza desde la primera página al buscar
    setCurrentPage(1);
    setCurrentClientes(filterClientes(1, searchTerm));
  };

  // Función para filtrar clientes por nombre de manera flexible
  const filterClientes = (page, searchTermR) => {
    setSearchTerm(searchTermR);
    const registrosIni = (page - 1) * clientesPerPage;

    const filtered = clientesList.filter((cliente) => {
      // Verifica que `nombreCompleto` sea una cadena antes de filtrar
      return (
        typeof cliente.nombreCompleto === "string" &&
        cliente.nombreCompleto.toLowerCase().includes(searchTermR.toLowerCase())
      );
    });

    const totalPages = Math.ceil(filtered.length / clientesPerPage);
    setTotalClientes(totalPages);

    return filtered.slice(registrosIni, registrosIni + clientesPerPage);
  };

  const handleNewCita = (id, clienteName) => {
    function generateTimeOptions(startTime, endTime, intervalMinutes) {
      const options = [];
      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);

      while (start <= end) {
        const hours = start.getHours().toString().padStart(2, "0");
        const minutes = start.getMinutes().toString().padStart(2, "0");
        options.push(
          `<option value="${hours}:${minutes}">${hours}:${minutes}</option>`
        );
        start.setMinutes(start.getMinutes() + intervalMinutes);
      }

      return options.join("");
    }

    // Función para cargar las citas existentes
    const cargarCitas = () => {
      const fechaCita = document.getElementById("fechaCita").value;
      const sucursalCita = document.getElementById("sucursalCita").value;

      if (!fechaCita) {
        return;
      }

      // Realizar petición para obtener las citas del día seleccionado
      axios
        .get("/citas/disponibilidad", {
          params: { fecha: fechaCita, sucursalId: sucursalCita },
        })
        .then((response) => {
          const { results } = response.data;
          const tablaCitas = document.getElementById("tablaCitas");

          // Limpiar la tabla
          tablaCitas.innerHTML = `
            <thead>
              <tr>
                <th style="padding:5px">Hora</th>
                <th style="padding:5px">Paciente</th>
                <th style="padding:5px">Teléfono</th>
                <th style="padding:5px">Confirmada</th>
                <th style="padding:5px">Cancelada</th>
                <th style="padding:5px">Creador</th>
              </tr>
            </thead>
            <tbody id="bodyCitasT"></tbody>
          `;

          const tbody = tablaCitas.querySelector("tbody");

          if (results.length > 0) {
            results.forEach((cita) => {
              const tr = document.createElement("tr");

              tr.innerHTML = `
                <td>${cita.hora}</td>
                <td>${cita.clienteNombre}</td>
                <td>${cita.telefonoCliente}</td>
                <td>${cita.confirmada == "0" ? "No" : "Si"}</td>
                <td>${cita.cancelada == "0" ? "No" : "Si"}</td>
                <td>${cita.nombreCreador}</td>
              `;

              tbody.appendChild(tr);
            });
          } else {
            tbody.innerHTML =
              "<tr><td colspan='6' style='text-align:center;'>No hay citas para esta fecha.</td></tr>";
          }
        })
        .catch((error) => {
          console.error("Error al obtener las citas existentes:", error);
          Swal.fire("¡Error!", "Hubo un error al cargar las citas.", "error");
        });
    };

    Swal.fire({
      title: "Nueva Cita",
      html: `
        <div class="ContenedorNuevaCita" style="display: flex; flex-direction: column;">
          <div class="form-container" style="margin-bottom: 20px;">
            <label for="fechaCita" style="font-weight:700;">Fecha</label>
            <input type="date" id="fechaCita" class="swal2-input" style="margin-top:5px; margin-bottom:15px; margin-left:5px;">
    
            <label for="sucursalCita" style="font-weight:700;margin-right:5px;">Sucursal</label>
            <select id="sucursalCita" class="swal2-input limited-width-select" style="margin-bottom:15px; margin-right:25px;">
              <option value="">Selecciona una sucursal</option>
              ${sucursalesList
                .map(
                  (suc) =>
                    `<option value="${suc.id}">${suc.sucursalNumber} - ${suc.direccion}</option>`
                )
                .join("")}
            </select>
    
            <label for="horaCita" style="font-weight:700;">Hora</label>
            <select id="horaCita" class="swal2-input" style="margin-top:5px; margin-left:5px;">
              ${generateTimeOptions("09:00", "19:30", 15)}
            </select>
            <br>
            <label for="telCliente" style="font-weight:700;">Teléfono Paciente</label>
            <input type="text" id="telCliente" class="swal2-input" maxlength="10" style="margin-top:5px;margin-left:5px;" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10);" placeholder="Teléfono (10 dígitos)">
          </div>
    
          <p style="font-weight:700; font-size: 22px;">Citas para el día seleccionado:</p>
          <div class="citas-container" style="max-height: 300px; overflow-y: auto;">
            <table id="tablaCitas" style="border-collapse: collapse; text-align: left;">
              <thead>
                <tr>
                  <th style="padding:5px">Hora</th>
                  <th style="padding:5px">Paciente</th>
                  <th style="padding:5px">Teléfono</th>
                  <th style="padding:5px">Confirmada</th>
                  <th style="padding:5px">Cancelada</th>
                  <th style="padding:5px">Creador</th>
                </tr>
              </thead>
              <tbody id="bodyCitasT"></tbody>
            </table>
          </div>
        </div>
      `,
      customClass: {
        popup: "custom-modal",
      },
      width: "1300px",
      focusConfirm: false,
      didOpen: () => {
        const fechaInput = document.getElementById("fechaCita");
        const sucursalSelect = document.getElementById("sucursalCita");

        fechaInput.addEventListener("change", cargarCitas);
        sucursalSelect.addEventListener("change", cargarCitas);
      },
      preConfirm: async () => {
        const fechaCita = document.getElementById("fechaCita").value;
        const horaCita = document.getElementById("horaCita").value;
        const sucursalCita = document.getElementById("sucursalCita").value;
        const telCliente = document.getElementById("telCliente").value;

        if (!fechaCita || !horaCita || !sucursalCita || !telCliente) {
          Swal.showValidationMessage("Por favor completa todos los campos");
          return false;
        }

        try {
          setLoading(true);
          const response = await axios.get("/citas/disponibilidad", {
            params: { fecha: fechaCita, sucursalId: sucursalCita },
          });

          const { results } = response.data;
          const horasOcupadas = results.map((cita) =>
            cita.hora.substring(0, 5)
          );

          if (horasOcupadas.includes(horaCita)) {
            setLoading(false);
            Swal.showValidationMessage("Esta hora ya está ocupada.");
            return false;
          }

          return { fechaCita, horaCita, sucursalCita, telCliente };
        } catch (error) {
          console.error("Error consultando disponibilidad:", error);
          setLoading(false);
          Swal.showValidationMessage("Error al verificar disponibilidad.");
          return false;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { fechaCita, horaCita, sucursalCita, telCliente } = result.value;

        let sucDirecc = sucursalesList.filter(
          (sucursales) => sucursales.id === parseInt(sucursalCita)
        );

        // Asegurarte de obtener la dirección de la primera coincidencia del filtro
        sucDirecc = sucDirecc.length > 0 ? sucDirecc[0].direccion : null;

        // Crear la cita si la hora está disponible
        const nuevaCita = {
          fecha: fechaCita,
          hora: horaCita,
          sucursalId: sucursalCita,
          sucursalDireccion: sucDirecc,
          idCliente: id,
          clienteName: clienteName,
          telefonoCliente: telCliente,
        };
        const fechaSecciones = fechaCita.split("-");
        const anio = fechaSecciones[0];
        const mes = fechaSecciones[1];
        const dia = fechaSecciones[2];
        const fechaEntregaFormato = `${dia}/${mes}/${anio}`;
        const nombreOptica = sucDirecc;
        const phoneNumber = "+52" + nuevaCita.telefonoCliente;
        const nombreCliente = nuevaCita.clienteName;
        const Hora = nuevaCita.hora;
        if (phoneNumber != "+520000000000" && phoneNumber != "+52") {
          axios
          .post("/whatsapp/AvisoCreacionCita", {
            Fecha: fechaEntregaFormato,
            Hora,
            nombreOptica,
            nombreCliente,
            phoneNumber,
          })
          .then((response) => {
            console.log(response);
          });
        }
        
        axios
          .post("/citas", nuevaCita, {
            headers: {
              iduser: localStorage.getItem("idUser"),
              name: localStorage.getItem("name"),
              lastname: localStorage.getItem("lastName"),
              secondLastName: localStorage.getItem("secondLastName"),
            },
          })
          .then(() => {
            setLoading(false);
            Swal.fire(
              "¡Cita Creada!",
              "La cita ha sido creada exitosamente.",
              "success"
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error creando la cita:", error);
            Swal.fire("¡Error!", "Hubo un error al crear la cita.", "error");
          });
      }
    });
  };

  const handleVerProximosDias = () => {
    // Mostrar el Swal para seleccionar la sucursal
    Swal.fire({
      title: "Selecciona una sucursal",
      html: `
        <select id="sucursalCita" class="swal2-input limited-width-select" style="margin-bottom:15px; margin-right:25px">
          <option value="">Selecciona una sucursal</option>
          ${sucursalesList
            .map(
              (suc) =>
                `<option value="${suc.id}">${suc.sucursalNumber} - ${suc.direccion}</option>`
            )
            .join("")}
        </select>
      `,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      preConfirm: () => {
        // Obtener el id de la sucursal seleccionada
        const selectedSucursal = document.getElementById("sucursalCita").value;
        if (!selectedSucursal) {
          // Validar que se haya seleccionado una sucursal
          Swal.showValidationMessage("Por favor, selecciona una sucursal.");
          return false;
        }
        return selectedSucursal; // Devuelve el id de la sucursal seleccionada
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedSucursal = result.value; // Aquí tenemos el id de la sucursal seleccionada

        // Generar horas de 9:00 AM a 7:30 PM con intervalos de 15 minutos
        const generarHoras = () => {
          const horas = [];
          for (let i = 9; i <= 19; i++) {
            for (let j = 0; j < 60; j += 15) {
              if (i === 19 && j > 30) break; // No generar horas después de las 7:30 PM
              horas.push(
                `${i.toString().padStart(2, "0")}:${j
                  .toString()
                  .padStart(2, "0")}`
              );
            }
          }
          horas.push("19:30"); // Añadir manualmente 7:30 PM
          return horas;
        };

        const horas = generarHoras();

        // Obtener la fecha de inicio (hoy) y la fecha fin (5 días después)
        const fechaInicio = new Date();
        const fechaFin = new Date(fechaInicio);
        fechaFin.setDate(fechaInicio.getDate() + 5);

        // Función para formatear fechas
        const formatFecha = (fecha) => {
          const options = {
            weekday: "long",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Mexico_City",
          };
          const formattedDate = fecha.toLocaleDateString("es-MX", options);
          const [dayOfWeek, dayMonthYear] = formattedDate.split(", ");
          const [day, month, year] = dayMonthYear.split("/");
          const displayDate = `${dayOfWeek}, ${day}/${month}/${year}`;
          return { formatted: `${year}-${month}-${day}`, display: displayDate };
        };

        // Generar todas las fechas entre inicio y fin
        const generarFechas = (inicio, fin) => {
          const fechas = [];
          let current = new Date(inicio);
          while (current <= fin) {
            fechas.push(formatFecha(current));
            current.setDate(current.getDate() + 1);
          }
          return fechas;
        };

        const fechas = generarFechas(fechaInicio, fechaFin);

        // Consultar la API con el id de la sucursal seleccionada
        axios
          .get("/citas/proximos-dias", {
            params: {
              fechaInicio: fechas[0].formatted,
              fechaFin: fechas[5].formatted,
              sucursalId: selectedSucursal, // Usamos el id de la sucursal seleccionada
            },
          })
          .then((response) => {
            const { data } = response.data;

            // Crear un objeto de citas por fecha y hora
            const citasPorFechaHora = {};
            Object.keys(data).forEach((key) => {
              const dateKey = new Date(key);
              const formattedDate = formatFecha(dateKey).formatted;
              data[key].forEach((cita) => {
                const citaHora = cita.hora;
                if (!citasPorFechaHora[formattedDate]) {
                  citasPorFechaHora[formattedDate] = {};
                }
                if (!citasPorFechaHora[formattedDate][citaHora]) {
                  citasPorFechaHora[formattedDate][citaHora] = [];
                }
                citasPorFechaHora[formattedDate][citaHora].push(cita);
              });
            });

            // Renderizar la tabla con los datos
            Swal.fire({
              title: "Citas de los Próximos 5 Días",
              html: `
                <div style="overflow-x: auto;">
                  <div style="max-height: 500px; overflow-y: auto; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 5px;">
                    <table style="border-collapse: collapse; width: 100%; text-align: left; font-family: Arial, sans-serif; font-size: 14px;">
                      <thead style="position: sticky; top: 0; background-color: #892323; color: white; z-index: 1; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);">
                        <tr>
                          <th style="padding: 10px; border-bottom: 2px solid #0056b3;">Hora</th>
                          ${fechas
                            .map(
                              (fecha) =>
                                `<th style="padding: 10px; border-bottom: 2px solid #0056b3;">${fecha.display}</th>`
                            )
                            .join("")}
                        </tr>
                      </thead>
                      <tbody>
                        ${horas
                          .map(
                            (hora, index) => `
                            <tr style="background-color: ${
                              index % 2 === 0 ? "#fefefe" : "#f3f3f3"
                            }; transition: background-color 0.3s;">
                              <td style="padding: 10px; border-bottom: 1px solid #ddd;">${hora}</td>
                              ${fechas
                                .map((fecha) => {
                                  const citasEnDia =
                                    citasPorFechaHora[fecha.formatted] || {};
                                  const citasEnHora =
                                    citasEnDia[hora + ":00"] || [];

                                  return citasEnHora.length > 0
                                    ? `<td style="padding: 10px; vertical-align: top; border-bottom: 1px solid #ddd; max-width: 90px">
                                        ${citasEnHora
                                          .map(
                                            (cita) => `
                                              <div style="margin-bottom: 10px; font-size: 13px; background-color: #e9f7ef; padding: 8px; border-radius: 5px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);">
                                                <strong style="color: #007BFF;">${
                                                  cita.clienteNombre
                                                }</strong><br>
                                                <span style="color: #555;">${
                                                  cita.telefonoCliente
                                                }</span><br>
                                                <span style="font-size: 12px; color: ${
                                                  cita.confirmada === 1
                                                    ? "green"
                                                    : "red"
                                                };">
                                                  ${
                                                    cita.confirmada === 1
                                                      ? "Confirmada"
                                                      : "No Confirmada"
                                                  }
                                                </span>
                                              </div>`
                                          )
                                          .join(
                                            "<hr style='border: none; border-top: 1px solid #ddd;'>"
                                          )}
                                      </td>`
                                    : `<td style="padding: 10px; vertical-align: top; text-align: center; color: #aaa; border-bottom: 1px solid #ddd;">-</td>`;
                                })
                                .join("")}
                            </tr>
                          `
                          )
                          .join("")}
                      </tbody>
                    </table>
                  </div>
                </div>

              `,
              width: "90%",
              confirmButtonText: "Cerrar",
            });
          })
          .catch((error) => {
            console.error("Error al cargar las citas:", error);
            Swal.fire("¡Error!", "No se pudieron cargar las citas.", "error");
          });
      }
    });
  };

  const navigate = useNavigate();
  const handleNewPedido = (cliente, sucursales) => {
    navigate("/dashboard/crearPedido", { state: { cliente, sucursales } });
  };

  const handleNewVisita = (cliente, sucursales) => {
    navigate("/historial/nuevaVisita", { state: { cliente, sucursales } });
  };

  const handleNewHistoria = (cliente, sucursales) => {
    navigate("/historial/nuevaHistoriaClinica", {
      state: { cliente, sucursales },
    });
  };

  const getHistoriaPDF = async (Cliente) => {
    const idCliente = Cliente.id;
    setLoading(true);
    try {
      const response = await axios.post(
        "/historiaClinica/validarHistoriaClinica",
        { idCliente }
      );
      const { existeHistoriaClinica } = response.data;
      if (existeHistoriaClinica) {
        const res = await axios.post("/historiaClinica/generatePDF", {
          idCliente,
        });
        // Obtener la URL del PDF desde la respuesta
        const pdfUrl = res.data.pdfUrl;
        setLoading(false);
        // Abre la URL del PDF en una nueva pestaña
        window.open(pdfUrl, "_blank");
      } else {
        setLoading(false);
        Swal.fire(
          "No hay registros!",
          "No se encontraron registros para el paciente seleccionado.",
          "info"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error al generar el PDF:", error);
      alert("Hubo un problema al generar el PDF.");
    }
  };

  const goToHistorialVisitas = async (Cliente) => {
    navigate("/visita/historial", { state: { Cliente } });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentClientes(filterClientes(page, searchTerm));
  };

  return (
    <div>
      <Header />
      <Loader isLoading={loading} />
      <div
        className="container formCliente"
        style={{ marginTop: "50px", marginLeft: "0" }}
      >
        <h2 className="mt-4 mb-3">Gestión de Pacientes</h2>
        <form onSubmit={handleCreateOrUpdate}>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Nombre Completo:*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Nombre Completo"
                value={nombreCompleto}
                onChange={(e) =>
                  setNombreCompleto(e.target.value.toUpperCase())
                }
                required
              />
            </div>
            <div className="col-md-4">
              <span>Fecha de Nacimiento:</span>
              <input
                type="date"
                className="form-control"
                placeholder="Ingresa Fecha de Nacimiento"
                value={fechaNacimiento}
                onChange={(e) => setFechaNacimiento(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>Sexo:</span>
              <select
                className="form-control"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option value="">Seleccionar Sexo</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Edad:*</span>
              <input
                type="number"
                className="form-control"
                placeholder="Ingresa Edad"
                value={edad}
                onChange={(e) => setEdad(e.target.value)}
                required
              />
            </div>
            <div className="col-md-4">
              <span>Domicilio:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Domicilio"
                value={domicilio}
                onChange={(e) => setDomicilio(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>Teléfono:*</span>
              <input
                type="tel"
                className="form-control"
                placeholder="Ingresa Teléfono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                pattern="[0-9]{10}"
                maxLength={10}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Segundo Teléfono:</span>
              <input
                type="tel"
                className="form-control"
                placeholder="Ingresa Segundo Teléfono"
                value={telefono2}
                onChange={(e) => setTelefono2(e.target.value)}
                pattern="[0-9]{10}"
                maxLength={10}
              />
            </div>
            <div className="col-md-4">
              <span>Email:</span>
              <input
                type="email"
                className="form-control"
                placeholder="Ingresa Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <span>RFC:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa RFC"
                value={rfc}
                onChange={(e) => setRfc(e.target.value.toUpperCase())}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <span>Código Postal:</span>
              <input
                type="text"
                className="form-control"
                placeholder="Ingresa Código Postal"
                value={codigoPostal}
                onChange={(e) => setCodigoPostal(e.target.value)}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {editMode ? "Actualizar" : "Crear"}
          </button>
        </form>
      </div>
      <div style={{ margin: "0 2%" }} className="formCliente">
        <h3 className="mt-5">Lista de Pacientes</h3>
        <div className="row mb-3" style={{ position: "relative" }}>
          <div className="col-md-4">
            <span>Buscar por Nombre:</span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa el nombre"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchTerm !== "" && (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setSearchTerm(""); // Restablece el estado del término de búsqueda
                  setCurrentPage(1); // Restablece la página actual
                  localStorage.removeItem("PacienteSearch"); // Elimina el término de búsqueda guardado
                  setCurrentClientes(filterClientes(1, "")); // Muestra todos los clientes
                }}
                style={{ marginTop: "8px" }}
              >
                <i className="bi bi-x-lg">Borrar búsqueda</i>
              </button>
            )}
          </div>
          <button
            onClick={handleVerProximosDias}
            className="btn btn-secondary"
            style={{
              position: "absolute",
              width: "fit-content",
              right: "20px",
            }}
          >
            Ver citas de próximos 5 días
          </button>
        </div>
        {currentClientes.length > 0 ? (
          <>
            <table
              className="table table-dark"
              style={{ borderRadius: "20px" }}
            >
              <thead>
                <tr>
                  <th style={{ borderTopLeftRadius: "20px" }}>
                    Nombre Completo
                  </th>
                  <th>Fecha de Nacimiento</th>
                  <th>Edad</th>
                  <th>Sexo</th>
                  <th>Domicilio</th>
                  <th>Teléfono</th>
                  <th>Segundo Teléfono</th>
                  <th>Email</th>
                  <th>RFC</th>
                  <th>Código Postal</th>
                  <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {currentClientes.map((cliente) => (
                  <tr key={cliente.id} className="tablaPedidos">
                    <td>{cliente.nombreCompleto}</td>
                    <td>
                      {cliente.fechaNacimiento
                        ? new Date(cliente.fechaNacimiento).toLocaleDateString()
                        : "Sin Fecha"}
                    </td>
                    <td>{cliente.edad}</td>
                    <td>{cliente.sexo}</td>
                    <td>{cliente.domicilio}</td>
                    <td>{cliente.telefono}</td>
                    <td>{cliente.telefono2}</td>
                    <td>{cliente.email}</td>
                    <td>{cliente.rfc}</td>
                    <td>{cliente.codigoPostal}</td>
                    <td>
                      <div className="dropdown">
                        <button
                          className={`dropbtn ${
                            openDropdown === cliente.id ? "active" : ""
                          }`}
                          onClick={() => toggleDropdown(cliente.id)}
                          aria-expanded={openDropdown === cliente.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <span style={{ fontSize: "30px" }}>⚙️</span>
                        </button>
                        {openDropdown === cliente.id && (
                          <div
                            className="dropdown-content"
                            style={{ animation: "fadeIn 0.3s ease" }}
                          >
                            <button onClick={() => handleEdit(cliente)}>
                              ✏️ Editar
                            </button>
                            <button
                              onClick={() =>
                                handleNewCita(
                                  cliente.id,
                                  cliente.nombreCompleto
                                )
                              }
                            >
                              📅 Nueva Cita
                            </button>
                            {userType !== "4" && (
                              <button
                                onClick={() =>
                                  handleNewPedido(cliente, sucursalesList)
                                }
                              >
                                📦 Nuevo Pedido
                              </button>
                            )}
                            <button
                              onClick={() => goToHistorialVisitas(cliente)}
                            >
                              🕒 Historial de Visitas
                            </button>
                            <button
                              onClick={() =>
                                handleNewVisita(cliente, sucursalesList)
                              }
                            >
                              🚶 Nueva Visita
                            </button>
                            {userType === "0" || userType === "4" ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleNewHistoria(cliente, sucursalesList)
                                  }
                                >
                                  📋 Historia Clínica
                                </button>
                                <button onClick={() => getHistoriaPDF(cliente)}>
                                  🖨️ Exportar Histórico
                                </button>
                              </>
                            ) : null}
                            {(userType === "0" || userType === "1") && (
                              <button
                                onClick={() => handleDelete(cliente.id)}
                                style={{ color: "red" }}
                              >
                                🗑️ Eliminar
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPages={totalClientes}
              itemsPerPage={10}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <h4 style={{ textAlign: "center", color: "lightgray" }}>
            No hay pacientes
          </h4>
        )}
      </div>
    </div>
  );
};

export default Clientes;
