import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import logo from "../../images/LogoTransparente.png";
import "../../index.css";

const PedidoEstatusUpdate = () => {
  const id =
    sessionStorage.getItem("idPedidoCurrent") ||
    sessionStorage.getItem("pedidoEstatus");
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPedido = async () => {
    try {
      const response = await axios.get(
        `/pedido/${id}`
      );
      setPedido(response.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener el pedido:", error);
      Swal.fire({
        icon: "error",
        title: "Error al obtener el pedido",
        text: "Hubo un problema al cargar los detalles del pedido. Por favor, inténtalo de nuevo más tarde.",
      }).then(() => {});
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("/login");
      return;
    }
    document.body.style.paddingTop = "20px";
    fetchPedido();
  }, [id]);

  const handlePedidoUpdate = () => {
    const estados = [
      "En proceso de elaboración",
      "Recibido en sucursal",
      "Entregado a cliente",
    ];
  
    let estadoIndex = estados.indexOf(pedido.estatus);
    let estadoSiguiente =
      estadoIndex !== -1 && estadoIndex < estados.length - 1
        ? estados[estadoIndex + 1]
        : null;
  
    let saldoPendiente = 0;
    let importe = 0;
    let html = `¿Desea actualizar el estado del pedido a <b>"${estadoSiguiente}"</b>?`;
    let inputOptions = null;
  
    if (pedido.estatus === "Recibido en sucursal") {
      saldoPendiente = 0;
      if (pedido.tipoPago === "Anticipo") {
        html = `¿Desea actualizar el estado del pedido a <b>"${estadoSiguiente}"</b> y confirmar que se pagó el saldo completo de <b>"$${pedido.saldoPendiente.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}"</b>?`;
        importe = pedido.saldoPendiente;
  
        // Opciones para el campo select
        inputOptions = {
          'Efectivo': 'Efectivo',
          'Transferencia': 'Transferencia',
          'Credito': 'Tarjeta de Crédito',
          'Debito': 'Tarjeta de Débito'
        };
  
        html += `<br><br><label for="formaPago">Forma de Pago:</label>`;
      }
    } else {
      saldoPendiente = pedido.saldoPendiente;
    }
  
    Swal.fire({
      title: "Actualizar Pedido",
      html: html,
      icon: "warning",
      input: inputOptions ? 'select' : null, // Agrega el campo select si es necesario
      inputOptions: inputOptions,
      inputPlaceholder: 'Seleccione una opción',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      preConfirm: (selectedValue) => {
        if (pedido.estatus === "Recibido en sucursal" && pedido.tipoPago === "Anticipo") {
          if (!selectedValue) {
            Swal.showValidationMessage('Debe seleccionar una forma de pago');
            return false; // Evita que se cierre el modal
          }
          return { formaPago: selectedValue }; // Devuelve la opción seleccionada
        }
        return {};
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const formaPagoLiquidacion = result.value.formaPago;
        axios
          .put(`/pedidoUpdateStatus/${id}`, {
            estatusActualizado: estadoSiguiente,
            ultimoModificador: `${localStorage.getItem(
              "name"
            )} ${localStorage.getItem("lastName")} ${localStorage.getItem(
              "secondLastName"
            )}`,
            idUltimoModificador: localStorage.getItem("idUser"),
            saldoPendiente: saldoPendiente,
            pedidoId: pedido.id,
            total: pedido.total,
            idCreador: localStorage.getItem("idUser"),
            nombreCreador: `${localStorage.getItem(
              "name"
            )} ${localStorage.getItem("lastName")} ${localStorage.getItem(
              "secondLastName"
            )}`,
            idCliente: pedido.idCliente,
            nombreCliente: pedido.nombreCliente,
            idSucursal: pedido.idSucursal,
            direccionSucursal: pedido.direccionSucursal,
            formaPago: pedido.formaPago,
            tipoPago: pedido.tipoPago,
            importe: importe,
            formaPagoLiquidacion: formaPagoLiquidacion
          })
          .then((response) => {
            axios.get(`/clientePorId/${pedido.idCliente}`)
            .then(response => {
              const cliente = response.data.data; // Asegúrate de acceder a los datos correctamente
              if (cliente) {
                axios.get("/sucursales").then((response)=>{
                  const listaSucursales = response.data.data;
                  const sucursalEncontrada = listaSucursales.find(
                    sucursal => sucursal.id == pedido.idSucursal
                   );
                  const nombreOptica =  sucursalEncontrada
                      ? sucursalEncontrada.direccion
                      : null;
                  const phoneNumber = "+52" + cliente.telefono;
                  const nombreCliente = pedido.nombreCliente;
                  switch (estados[estadoIndex]) {
                    case "Esperando elaboración" : {
                      break;
                    }
                    case "Enviado a sucursal" : {
                      cliente.telefono != "0000000000" && cliente.telefono != "" ? axios.post("/whatsapp/AvisoPedidoListoRecogerSucursal", { nombreCliente, nombreOptica, phoneNumber }) : null;
                      break;
                    }
                  }
                });
              }
            })
            if (response.data.success) {
              // Si la solicitud fue exitosa, actualiza la lista de usuarios
              fetchPedido();
              // Muestra un mensaje de éxito al usuario
              Swal.fire({
                title: "¡Pedido actualizado!",
                text: "El pedido se ha actualizado exitosamente.",
                icon: "success",
                timer: 1000, // La alerta desaparecerá después de 2 segundos (2000 ms)
                showConfirmButton: false, // Oculta el botón de confirmación
              });
            } else {
              // Si la solicitud no fue exitosa, muestra un mensaje de error al usuario
              Swal.fire({
                title: "¡Error!",
                text: "No se pudo actualizar el estatus del pedido.",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            // Si hubo un error en la solicitud, muestra un mensaje de error al usuario
            console.error(
              "Error al eliminar actualizar el estatus del pedido:",
              error
            );
            Swal.fire({
              title: "¡Error!",
              text: "Ocurrió un error al intentar eliminar el usuario.",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else {
        Swal.close();
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const formatDateWithoutTime = (dateString) => {
    const formattedDate = formatDate(dateString);
    return formattedDate.split(",")[0];
  };

  const handleUltimaVisita= (idCliente, id) => {
    navigate("/visita/ultimaVisita", { state: { idCliente, id} });
  };
  

  if (loading) {
    return <div style={{ margin: "auto" }}>Cargando pedido...</div>;
  }

  if (!pedido) {
    return <div>No se encontró el pedido.</div>;
  }
  return (
    <div style={{ display: "grid" }}>
      <button className="btn btn-sm btn-primary" onClick={() => handleUltimaVisita(pedido.idCliente, pedido.id)} style={{width: '200px', justifySelf: 'end', marginRight: '20px'}}>Ultima Visita del Cliente</button>
      <img src={logo} alt="" style={{ margin: "auto", width: "150px" }} />
      <div className="container mt-4">
        <div className="card bg-light text-black mb-3">
          <div className="card-body titlePedidoVista">
            <h2 className="mb-4" style={{ color: "black" }}>
              Detalles del Pedido
            </h2>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Nombre Cliente</h5>
            <p className="card-text">{pedido.nombreCliente}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Dirección Sucursal</h5>
            <p className="card-text">{pedido.direccionSucursal}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Nombre Empleado</h5>
            <p className="card-text">{pedido.nombreCreador}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Estatus</h5>
            <p className="card-text">{pedido.estatus}</p>
          </div>
        </div>
        {pedido.entregaRealizada !== null ? (
          <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Entrega Realizada</h5>
            <p className="card-text">{formatDate(pedido.entregaRealizada)}</p>
          </div>
        </div>
        ) : null}
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Fecha de Entrega Prevista</h5>
            <p className="card-text">
              {formatDateWithoutTime(pedido.fechaEntregaPrevista)}
            </p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Total</h5>
            <p className="card-text">${pedido.total}</p>
          </div>
        </div>
        <div className="card bg-light text-black mb-3">
          <div className="card-body">
            <h5 className="card-title">Saldo Pendiente</h5>
            <p className="card-text">${pedido.saldoPendiente}</p>
          </div>
        </div>
      </div>
      {pedido.estatus !== "Entregado a cliente" ? (
        <>
          <button
            className="btn btn-sm btn-primary"
            onClick={handlePedidoUpdate}
            style={{width: '200px', justifySelf: 'center', marginBottom: '10px'}}
          >
            Actualizar Estatus
          </button>
        </>
      ) : null}
    </div>
  );
};

export default PedidoEstatusUpdate;
